<template>
  <div class="box">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="directory">
        <div class="directory-name">
          <span>{{ title }}目录</span>
        </div>
        <div>
          <ul class="directory-list">
            <li :class="[
                'directory-item',
                activeItem === directory.id ? 'directory-item-active' : '',
              ]" v-for="directory in directoryList" :key="directory.id" @click="getContent(directory.id)">
              {{ directory.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="content-list">
        <div class="content-item" v-for="content in contentList" :key="content.id">
          <div class="content-item-left">
            <span :title="content.name" class="content-item-left-name">{{ content.name }}</span>
          </div>
          <div class="content-item-right" @click="handleContent(content)">
            点击进入>
          </div>
        </div>
      </div>
    </div>
    <menu-pop :activeItem="3"></menu-pop>
    <back :pageDepth="3"></back>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop'
import navRouter from '@/components/navRouter'
import back from '@/components/back'
import { API_MODULE } from '@/request/api'
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CustomizeColumn',
  data() {
    return {
      icon: redIcon,
      title: '',
      activeItem: '',
      directoryList: [],
      contentList: [],
      id: null
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
    } else {
      this.icon = blueIcon
    }
    this.id = this.$route.query.id
    this.title = this.$route.query.title

    this.getData()

    this.useRouterList.find((router, i) => {
      if (!router) return
      if (router.path === this.$route.fullPath) {
        this.useRouterList.splice(i + 1)
      }
    })
    this.setRouterList(this.useRouterList, this.$route.fullPath)
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
      } else {
        this.icon = blueIcon
      }
    }
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    getData() {
      this.POST(API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
        catId: [this.id]
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.directoryList = res.data.model
            if (this.directoryList.length >= 0) {
              this.getContent(this.directoryList[0].id)
            }
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },

    getContent(id) {
      this.activeItem = id
      this.POST(API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
        catId: [id]
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.contentList = res.data.model
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    handleContent(content) {
      this.$router.push({
        path: `/layout/customize/CONTENT`,
        query: { id: content.id }
      })

      // 保存下一级路径
      const path = `/layout/customize/CONTENT?id=${content.id}`
      this.useRouterList.push({
        name: content.name,
        path: path
      })
      this.setRouterList(this.useRouterList, path)
    }
  },
  components: {
    menuPop,
    navRouter,
    back
  }
}
</script>

<style scoped lang="scss">
@import '@/themes/_handle.scss';

.box {
  .router-nav {
    width: 100%;
    height: 0.6rem;
    background-color: #ffffff;
    padding-left: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .nav-left {
      display: flex;

      .router-name {
        font-size: 0.16rem;
        @include font_color('font-medium');
        margin-left: 10px;

        .sub-router {
          font-size: 0.16rem;
          color: #cca766;
        }
      }
    }
  }

  .content {
    padding: 0.6rem 0 0 0.6rem;
    box-sizing: border-box;
    @include background_color('background-normal');
    display: flex;
    justify-content: flex-start;
    min-height: 13.6rem;
    .directory {
      width: 4.9rem;
      height: 11.8rem;
      @include background_color('background-light');
      opacity: 1;
      border-radius: 0.05rem;
      padding: 0.65rem 0.48rem;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: scroll;
      margin-right: 0.6rem;

      .directory-name {
        font-size: 0.36rem;
        color: #ffffff;
        opacity: 1;
        padding-bottom: 0.28rem;
        border-bottom-style: solid;
        border-width: 0.01rem;
        font-weight: 600;
        @include border_color('border-witer');
        text-align: left;
      }

      .directory-list {
        list-style: none;
        margin-top: 0.74rem;

        .directory-item {
          margin-bottom: 0.6rem;
          padding-left: 0.14rem;
          box-sizing: border-box;
          text-align: left;
          font-size: 0.36rem;
          min-height: 0.36rem;
          font-weight: 400;
          color: #ffd0d0;
          opacity: 1;
          border-left-style: solid;
          border-width: 0.05rem;
          border-radius: 0.02rem;
          @include border_color('border-witer');
        }

        .directory-item-active {
          font-size: 0.48rem;
        }
      }
    }

    .content-list {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-right: 0.6rem;
      @include background_color('background-light');
      max-height: 11.8rem;
      overflow: auto;
      .content-item {
        min-height: 0.8rem;
        width: 100%;
        background: #ffffff;
        border: 0.1rem solid rgba(180, 0, 0, 0.4);
        box-shadow: 0 0.03rem 0.06rem rgba(180, 0, 0, 0.2);
        border-radius: 0.05rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0.78rem;
        padding-right: 1rem;
        margin-bottom: 0.6rem;

        .content-item-left {
          font-size: 0.48rem;
          color: #555555;
          display: flex;
          align-items: center;
          width: 80%;
          img {
            width: 1rem;
            height: 1rem;
            border-radius: 0.1rem;
            font-weight: bold;
            margin-right: 0.5rem;
          }
          .content-item-left-name {
            overflow: hidden;
            /*文本不会换行*/
            white-space: nowrap;
            /*当文本溢出包含元素时，以省略号表示超出的文本*/
            text-overflow: ellipsis;
          }
        }

        .content-item-right {
          font-weight: bold;
          font-size: 0.36rem;
          display: flex;
          align-items: center;

          @include font_color('font-medium');

          .click {
            color: #c3c3c3;
          }

          .exam-click {
            color: #c3c3c3;
            font-size: 0.3rem;
          }
        }
      }
    }
  }
}
</style>
